import { render, staticRenderFns } from "./addDoctor.vue?vue&type=template&id=7049c3db&scoped=true"
import script from "./addDoctor.vue?vue&type=script&lang=js"
export * from "./addDoctor.vue?vue&type=script&lang=js"
import style0 from "./addDoctor.vue?vue&type=style&index=0&id=7049c3db&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7049c3db",
  null
  
)

export default component.exports